import React from 'react';

interface HelpMessageProps {
  text: string
}

export function HelpMessage({ text }: HelpMessageProps) {
  return (
    <div className="mt-3 mb-0 text-info fs-sm d-flex gap-2 align-items-center">
      <figure className="mb-0">
        <img src="/static/img/icons/help-primary-icon.svg" alt="help message icon" />
      </figure>

      <span className="d-inline-block">{text}</span>
    </div>
  );
}
