/* eslint-disable @typescript-eslint/no-misused-promises */
import { BREAKPOINTS } from '../../utils/bootstrap';
import { getUserContext } from '../../utils/user';
import { setNotificationsAsRead } from './notificationsService';

function initializeNotificationsPopup() {
  const notificationsPopupElement = document.querySelector<HTMLDivElement>('.notifications-popup');

  if (!notificationsPopupElement) return;

  notificationsPopupElement.addEventListener('shown.bs.offcanvas', async () => {
    const userId = getUserContext('USER_ID') as number;
    await setNotificationsAsRead(userId);
  });

  notificationsPopupElement.addEventListener('hidden.bs.offcanvas', () => {
    const notificationButton = document.querySelector<HTMLButtonElement>('.notifications-popup-button');
    const notificationItems = notificationsPopupElement.querySelectorAll('.notification-content');

    notificationButton?.classList.remove('unread');
    notificationItems.forEach((item) => item.classList.remove('unread'));
  });
}

function handlePopupPosition() {
  const POPUP_BUTTON_GAP = 16;
  const notificationsPopupButtonElement = document.querySelector<HTMLButtonElement>('.notifications-popup-button');
  const notificationsPopupElement = document.querySelector<HTMLDivElement>('.notifications-popup');

  if (!notificationsPopupButtonElement || !notificationsPopupElement) return;

  const computePosition = () => {
    const rightOffset = window.innerWidth
      - notificationsPopupButtonElement.offsetLeft
      - notificationsPopupButtonElement.offsetWidth
      - POPUP_BUTTON_GAP;

    notificationsPopupElement.style.right = `${rightOffset}px`;
  };

  computePosition();

  window.addEventListener('resize', () => {
    if (window.innerWidth <= BREAKPOINTS.lg) {
      notificationsPopupElement.style.right = '0px';
      return;
    }

    computePosition();
  });
}

window.addEventListener('DOMContentLoaded', () => {
  initializeNotificationsPopup();
  handlePopupPosition();
}
);
