/* eslint-disable react/require-default-props */
import React from 'react';
import { Popover as BootstrapPopover, OverlayTrigger } from 'react-bootstrap';

import { OverlayTriggerType } from 'react-bootstrap/esm/OverlayTrigger';
import { Placement } from 'react-bootstrap/esm/types';
import { formatMoney } from '../../utils/formatters';

interface PopoverProps {
  id: string
  trigger: OverlayTriggerType
  width: number
  placement?: Placement
  renderContent: () => JSX.Element
  renderTrigger: () => JSX.Element
}

export function Popover({
  id, trigger, width, placement, renderTrigger, renderContent,
}: PopoverProps) {
  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={(
        <BootstrapPopover id={id} className="base-popover" style={{ width }}>
          <div className="content">{renderContent()}</div>
        </BootstrapPopover>
      )}
    >
      {renderTrigger()}
    </OverlayTrigger>
  );
}

export interface HelpPopoverProps {
  id: string
  width: number
  renderContent: () => JSX.Element
  placement?: Placement
  iconSrc?: string
}

export function HelpPopover({
  id, width, renderContent, placement, iconSrc = '/static/img/icons/help.svg',
}: HelpPopoverProps) {
  return (
    <Popover
      id={id}
      width={width}
      placement={placement}
      renderTrigger={() => <img className="mb-1" src={iconSrc} alt="Más información" />}
      trigger="hover"
      renderContent={renderContent}
    />
  );
}

export function RegimeHelpPopover() {
  const POPOVER_WIDTH = 480;
  const HELP_ICON_SRC = '/static/img/icons/help.svg';

  return (
    <Popover
      id="regime-help"
      width={POPOVER_WIDTH}
      trigger="hover"
      renderTrigger={() => <img className="mb-1" src={HELP_ICON_SRC} alt="Más información" />}
      renderContent={() => (
        <div className="d-flex align-items-start gap-2 p-1">
          <img src="/static/img/icons/help-light.svg" width={48} alt="" />

          <section>
            <header>
              <h6 className="fw-bold text-white mb-0">¡Atención!</h6>
            </header>

            <div className="text-white">
              <span>Se recomienda </span>
              <span className="fw-bold">Régimen A </span>
              <span>para clientes con tramo de impuesto cuya tasa es inferior al 15% y </span>
              <span className="fw-bold">Régimen B </span>
              <span>
                para clientes que se encuentren en un tramo de
                impuestos con una tasa por sobre el 15%.
              </span>
            </div>
          </section>
        </div>
      )}
    />
  );
}

interface SavingsCapacityPopoverProps {
  liquidityDecrement: number
  expectedMonthlySavings: number
}

export function SavingsCapacityPopover({
  expectedMonthlySavings,
  liquidityDecrement,
}: SavingsCapacityPopoverProps) {
  const POPOVER_WIDTH = 480;
  const HELP_ICON_SRC = '/static/img/icons/help.svg';

  return (
    <Popover
      id="savings-capacity-help"
      width={POPOVER_WIDTH}
      trigger="hover"
      renderTrigger={() => <img className="mb-1" src={HELP_ICON_SRC} alt="Más información" />}
      renderContent={() => (
        <div className="d-flex align-items-start gap-2 p-1">
          <img src="/static/img/icons/help-light.svg" width={48} alt="" />

          <section>
            <header>
              <h6 className="fw-bold text-white mb-0">¡Atención!</h6>
            </header>

            <div className="text-white">
              <span>Si el cliente está dispuesto a disminuir su liquidez en </span>
              <span className="fw-bold">{`${formatMoney(liquidityDecrement)} `}</span>
              <span>debería ahorrar mensualmente </span>
              <span className="fw-bold">{`${formatMoney(expectedMonthlySavings)}, `}</span>
              <span>
                {'considerando que la diferencia es el beneficio tributario. '}
              </span>
              <span>
                {'El monto máximo a ahorrar con beneficio tributario vía empleador es de '}
              </span>
              <span className="fw-bold">
                50 UF mensuales.
              </span>
            </div>
          </section>
        </div>
      )}
    />
  );
}
